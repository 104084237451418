.csg-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 50%;
}

.csg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.csg-text-container {
	padding: 25px;
}

.csg-buttons-container {
	margin-top: 30px;
	list-style-type: none;
	display: flex;
	justify-content: center;
}

.csg-buttons {
	display: flex;
	flex: 0 0 30%;
	align-items: center;
	justify-content: center;
}

.csg-buttons-small {
	display: flex;
	flex: 0 0 15%;
	align-items: center;
	justify-content: center;
}

.csg-margin-right {
	margin-right: 25px;
}

.csg-text {
	text-align: justify;
}

.csg-text-highlight {
	color: var(--bs-primary);
}

.csg-text-red {
	color: red;
}

.csg-text-yellow {
	color: yellow !important;
}

.csg-square-left {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.csg-square-right {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.csg-loading-container {
	padding: 80px;
}

.tooltip-inner {
	background-color: #377dff !important;
	/*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
	color: #fff;
}

.tooltip.top .tooltip-arrow {
	border-top-color: #377dff;
}

.tooltip.right .tooltip-arrow {
	border-right-color: #377dff;
}

.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #377dff;
}

.tooltip.left .tooltip-arrow {
	border-left-color: #377dff;
}

.fullscreen {
	z-index: 9999;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.csg-tabela-loader {
	z-index: 9999;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.2);
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
